import * as React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag"


function Social(props) {
  return (


          <div>
              <OutboundLink
                href="https://www.instagram.com/artandwriting/"
                className="social-instagram"
                rel="external"
                title="Follow us on Instagram"
                target="_blank"
>
                 <svg
      width={42}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11.922C0 5.338 5.505 0 12.295 0h16.721c6.79 0 12.296 5.338 12.296 11.922v16.155C41.312 34.663 35.806 40 29.016 40h-16.72C5.504 40 0 34.662 0 28.078V11.922zm3.811-.476c0-4.28 3.579-7.75 7.992-7.75h17.705c4.414 0 7.992 3.47 7.992 7.75v17.228c0 4.28-3.578 7.75-7.992 7.75H11.803c-4.413 0-7.992-3.47-7.992-7.75V11.446zM34.12 9.27c0 1.333-1.115 2.414-2.49 2.414-1.375 0-2.49-1.08-2.49-2.414s1.115-2.415 2.49-2.415c1.375 0 2.49 1.081 2.49 2.415zM20.656 30.283c5.873 0 10.635-4.617 10.635-10.313 0-5.695-4.762-10.313-10.635-10.313-5.874 0-10.635 4.617-10.635 10.313 0 5.696 4.761 10.313 10.635 10.313zm.03-3.636c3.82 0 6.916-3.003 6.916-6.707 0-3.704-3.096-6.706-6.916-6.706-3.82 0-6.915 3.002-6.915 6.706 0 3.704 3.096 6.707 6.915 6.707z"
        fill="#fff"
      />
    </svg>
              </OutboundLink>
            
         
              <OutboundLink
                href="https://twitter.com/artandwriting"
                className="social-twitter"
                rel="external"
                title="Follow us on Twitter"
                target="_blank"

              >
                <svg
      width={44}
      height={39}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_530_75)">
        <path
          d="M34.671.22h6.702L26.658 16.582 43.85 38.781H30.359l-10.563-13.49-12.092 13.49H1.002l15.589-17.502L.128.219h13.826l9.543 12.323L34.67.219zm-2.345 34.72h3.715L12 3.918H8.01l24.316 31.02z"
          fill="#F5F5F5"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_530_75">
          <path
            fill="#fff"
            transform="translate(0 .22)"
            d="M0 0h44v38.562H0z"
          />
        </clipPath>
      </defs>
    </svg>
                {/* <svg
      width={46}
      height={41}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_530_75)">
        <path
          d="M36.144.5h6.952L27.832 17.474 45.665 40.5H31.671L20.714 26.507 8.171 40.5H1.219l16.17-18.155L.314.5h14.341l9.899 12.782L36.144.5zm-2.433 36.015h3.854L12.629 4.338H8.49L33.71 36.515z"
          fill="#F5F5F5"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_530_75">
          <path
            fill="#fff"
            transform="translate(.18 .5)"
            d="M0 0h45.641v40H0z"
          />
        </clipPath>
      </defs>
    </svg> */}
                 {/* <svg
      width={43}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.92 0C3.608 0 .923 2.6.923 5.806v28.388C.923 37.4 3.608 40 6.92 40h29.318c3.312 0 5.996-2.6 5.996-5.806V5.805C42.234 2.6 39.55 0 36.238 0H6.92zm27.249 9.413c-.408 1.218-1.276 2.239-2.404 2.884a11.338 11.338 0 003.14-.82 10.836 10.836 0 01-2.728 2.697 13.637 13.637 0 01.015.675c0 6.886-5.5 14.828-15.558 14.828-3.087 0-5.962-.863-8.381-2.341.428.048.863.073 1.304.073 2.562 0 4.92-.834 6.79-2.231-2.392-.043-4.412-1.55-5.107-3.62a5.76 5.76 0 002.469-.09c-2.501-.478-4.386-2.585-4.386-5.11v-.065a5.67 5.67 0 002.477.652c-1.467-.936-2.432-2.53-2.432-4.339 0-.955.27-1.85.74-2.62 2.697 3.154 6.726 5.229 11.27 5.445a5.015 5.015 0 01-.14-1.187c0-2.878 2.447-5.212 5.467-5.212a5.59 5.59 0 013.991 1.646 11.295 11.295 0 003.473-1.265z"
        fill="#fff"
      />
    </svg> */}
              </OutboundLink>
            
         
              <OutboundLink
                href="https://www.facebook.com/ScholasticArtandWritingAwards "
                className="social-facebook"
                rel="external"
                target="_blank"
                title="Follow us on Facebook"

              >
                <svg
      width={42}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.663 0C2.983 0 0 2.889 0 6.452v27.096C0 37.111 2.983 40 6.663 40h27.985c3.68 0 6.663-2.889 6.663-6.452V6.452C41.312 2.889 38.329 0 34.648 0H6.663zm13.993 40l6.108-.006v-15.82h7.098l.045-6.163-7.097-.001.074-3.72c0-1.128.711-2.28 1.358-2.28l5.726.001.014-6.205h-5.487c-4.538 0-7.719 3.84-7.719 8.552l-.074 3.66-5.997-.007-.046 6.163 5.997.005V40z"
        fill="#fff"
      />
    </svg>
              </OutboundLink>
            
         
              <OutboundLink
                href="https://www.linkedin.com/groups/4001380/"
                className="social-linkedin"
                rel="external"
                title="Follow us on LinkedIn"
                target="_blank"

              >
  <svg
      width={40}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M39.756 3.061c0-.147-.009-.294-.031-.442-.208-1.352-1.428-2.408-2.928-2.4-5.594.031-11.189.014-16.783.014h-5.361c-3.834 0-7.664.023-11.497-.01C1.569.207.242 1.512.244 3.155.258 14.389.25 25.625.256 36.858c0 .303.041.617.125.909.372 1.26 1.427 2.01 2.81 2.01h33.606c.075 0 .147 0 .222-.002a2.812 2.812 0 002.737-2.828V3.061zM11.539 34.02c-1.686-.019-3.375-.014-5.064-.002-.325.002-.447-.087-.444-.44.01-6.046.01-12.094 0-18.14 0-.356.119-.431.444-.429 1.7.014 3.4.017 5.1-.002.353-.003.433.119.433.447-.008 3.025-.005 6.047-.005 9.072 0 3-.006 5.997.008 8.997 0 .361-.086.503-.472.497zm-2.53-21.538a3.4 3.4 0 01-3.417-3.425 3.413 3.413 0 013.436-3.448c1.9 0 3.425 1.545 3.422 3.467 0 1.892-1.533 3.405-3.442 3.405zm24.969 21.052c.003.373-.1.492-.475.486-1.664-.016-3.328-.005-4.992-.008-.478 0-.489-.014-.492-.506 0-3.11.014-6.222-.01-9.333a10.888 10.888 0 00-.245-2.305c-.311-1.375-1.189-2.042-2.589-2.059-.439-.005-.892.02-1.314.128-1.367.347-1.942 1.389-2.161 2.67a12.336 12.336 0 00-.153 1.947c-.014 3-.01 6 0 9 .003.353-.1.47-.455.466a280.258 280.258 0 00-5.064 0c-.361.003-.456-.122-.456-.472.009-6.025.009-12.05 0-18.072 0-.347.086-.47.453-.467 1.625.02 3.253.017 4.88 0 .334-.002.426.109.417.425-.02.686-.005 1.372-.005 2.003.489-.508.944-1.083 1.497-1.536 1.317-1.078 2.853-1.511 4.547-1.333.767.08 1.55.139 2.286.344 2.145.6 3.367 2.13 3.85 4.23.27 1.167.43 2.384.45 3.578.059 3.603.017 7.209.03 10.814z"
        fill="#fff"
      />
    </svg>
  
              </OutboundLink>
            

        </div>



  );
}

export default Social;