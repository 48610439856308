import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

function TopNav({ width, toggle }) {
  const [openTopNav, setOpenTopNav] = useState(false)

  const toggleMenu = () => {
    setOpenTopNav(!openTopNav)
    toggle(openTopNav)
  }

  // monitor the state of the toggle
  // add/remove click event handler to the document
  useEffect(() => {
    const clickHandler = ({ target }) => {
      const container = document.getElementById(`header-menu`)
      if (container.contains(target)) return
      setOpenTopNav(false)
    }

    document.addEventListener("click", clickHandler)

    // these functions clean up the event listeners
    return () => document.removeEventListener("click", clickHandler)
  })

  // same but for keypresses
  // if the esc key is pressed close the toggles
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (keyCode !== 27) return
      setOpenTopNav(false)
    }
    document.addEventListener("keydown", keyHandler)

    return () => document.removeEventListener("keydown", keyHandler)
  })

  return (
    <div id="header-menu">
      <div
        role="navigation"
        onClick={toggleMenu}
        id="header-menu-trigger"
        title="Menu"
      >
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
      </div>

      <nav
        id="header-nav-top-mobile"
        className={openTopNav === true ? "top-menu-active" : "top-menu-off"}
      >
        <ul>
          <li>
            <Link to="/students">Students</Link>
          </li>
          <li>
            <Link to="/alumni">Alumni</Link>
          </li>
          <li>
            <Link to="/educators">Educators</Link>
          </li>
          <li>
            <Link to="/parents">Parents</Link>
          </li>
        </ul>
      </nav>

    </div>
  )
}

export default TopNav
