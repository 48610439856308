import React, { useState, useEffect } from "react";

import socialInstagram from "../assets/images/common/social-instagram.svg"
import socialTwitter from "../assets/images/common/social-twitter.svg"
import socialYouTube from "../assets/images/common/social-youtube.svg"
import socialFacebook from "../assets/images/common/social-facebook.svg"
import socialLinkedIn from "../assets/images/common/social-linkedin.svg"
import socialX from '../assets/images/common/social-x-black.svg'

function Follow({id, children}) {

  const [openFollowMenu, setopenFollowMenu] = useState(false);
  const toggleMenu = () => {
    setopenFollowMenu(!openFollowMenu);
  };

  // monitor the state of the toggle
  // add/remove click event handler to the document
  useEffect(() => {
    const clickHandler = ({ target }) => {
      const container = document.getElementById(`header-follow`);
      if (container.contains(target)) return;
      setopenFollowMenu(false);
    };

    document.addEventListener("click", clickHandler);

    // these functions clean up the event listeners
    return () => document.removeEventListener("click", clickHandler);
  });

  // same but for keypresses
  // if the esc key is pressed close the toggles
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (keyCode !== 27) return;
      setopenFollowMenu(false);
    };
    document.addEventListener("keydown", keyHandler);

    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div id={id}>
      <div aria-expanded={openFollowMenu === true ? "true" : "false"} onClick={toggleMenu} className="trigger">         
            {children}
      </div>
      {openFollowMenu ?
              <div className="menu">
              <ul className="social-media">
                <li>
                  <a
                    href="https://www.instagram.com/artandwriting/"
                    className="social-instagram"
                    rel="external"
                    title="Follow us on Instagram"
                    style={{ backgroundImage: "url(" + socialInstagram + ")" }}
                  >
                    <span>Instagram</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/artandwriting"
                    className="social-twitter"
                    rel="external"
                    title="Follow us on Twitter"
                    style={{ backgroundImage: "url(" + socialX + ")" }}
                  >
                    <span>Twitter</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/ScholasticArtandWritingAwards "
                    className="social-facebook"
                    rel="external"
                    title="Follow us on Facebook"
                    style={{ backgroundImage: "url(" + socialFacebook + ")" }}
                  >
                    <span>Facebook</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/user/ScholasticGoldKey"
                    className="social-youtube"
                    rel="external"
                    title="Follow us on YouTube"
                    style={{ backgroundImage: "url(" + socialYouTube + ")" }}
                  >
                    <span>YouTube</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/groups/4001380/"
                    className="social-linkedin"
                    rel="external"
                    title="Follow us on LinkedIn"
                    style={{ backgroundImage: "url(" + socialLinkedIn + ")" }}
                  >
                    <span>LinkedIn</span>
                  </a>
                </li>
              </ul>
            </div>
       : null }
    </div>




  );
}

export default Follow;
