import React from "react";
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import searchIcon from "../assets/images/common/search.svg"
import TopNavToggle from "./top-nav-header"
import Follow from "./follow"

const Header = ({width, toggle}) => {

  return (
    <header>
        <h1 id="header-logo">
        <Link to="/" aria-label="Alliance for Young Artists &amp; Writers" ><span>
            Scholastic Art &amp; Writing Awards - Alliance for Young Artists
            &amp; Writers
          </span></Link>
        </h1>
 
    <nav id="header-nav-top">
				<ul>
					<li><Link to="/students">Students</Link></li>
					<li><Link to="/alumni">Alumni</Link></li>
					<li><Link to="/educators">Educators</Link></li>
					<li><Link to="/parents">Parents</Link></li>
				</ul>
			</nav>


      <TopNavToggle width={width} toggle={toggle}/>
      <div id="header-extras">
        <div id="header-search">
        <Link  to="/search">
            <img
              id="header-search-button"
              src={searchIcon}
              alt="Search"
              title="Search"
            />
      </Link>
        </div>

        <Follow id={"header-follow"}>Follow</Follow>
        <div id="header-help">
         <Link  to="/frequently-asked-questions/">Questions?</Link>
         </div>
        <div id="header-login">
          <OutboundLink href="https://portal.artandwriting.org/">Login</OutboundLink>
        </div>
               
            </div>

    
    </header>
  )
}

export default Header
