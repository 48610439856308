import React, { useState, useEffect } from "react";

function ToggleSection({toggleSection, children}) {


  const [openSection, setOpenSection] = useState(false);
  const toggleSectionNav = () => {
    setOpenSection(!openSection);
    toggleSection(openSection)
  };

  // if the esc key is pressed close the toggle
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (keyCode !== 27) return;
      setOpenSection(false);
    };
    document.addEventListener("keydown", keyHandler);

    return () => document.removeEventListener("keydown", keyHandler);
  });


return <span onClick={toggleSectionNav} >{children}</span>;
}

export default ToggleSection;
