import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import "../assets/styles/ui-1.2.css"
import "../assets/styles/fonts.css"
import "../assets/styles/global.css"
import "../assets/styles/pages.css"
import "../assets/styles/displays.css"

import "../assets/styles/loading-spinner.css"

import Alert from "./alert"
import Header from "./header"
import Menu from "./menu"
import MenuMobile from "./menu-mobile"
import Footer from "./footer"



const Layout = ({ isHomePage, width, toggleSection, children }) => {

  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  const [openMobileMenu, setOpenMobileMenu] = useState(false)

  const toggleNav = toggle => {
    setOpenMobileMenu(!openMobileMenu)
  }

  // monitor the state of the toggle
  // add/remove click event handler to the document
  useEffect(() => {
    const clickHandler = ({ target }) => {
      const container = document.getElementById(`header-menu`)
      if (container.contains(target)) return
      setOpenMobileMenu(false)
    }

    document.addEventListener("click", clickHandler)

    // these functions clean up the event listeners
    return () => document.removeEventListener("click", clickHandler)
  })

  // same but for keypresses
  // if the esc key is pressed close the toggles
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (keyCode !== 27) return
      setOpenMobileMenu(false)
    }
    document.addEventListener("keydown", keyHandler)

    return () => document.removeEventListener("keydown", keyHandler)
  })

  useEffect(() => {
    const widthHandler = ({ width }) => {
      if (width > 850) return
      setOpenMobileMenu(false)
    }
    window.addEventListener("resize", widthHandler)

    return () => window.removeEventListener("resize", widthHandler)
  })

  return (
    <div>
            {/* COMMENT/UNCOMMENT below to re-activate/disable site-wide banner  */}
            {/* <Alert />  */}
          
            <Header width={width} toggle={toggleNav} />
            <Menu width={width} toggleSection={toggleSection} />
            {openMobileMenu ? (
              <MenuMobile width={width} toggleSection={toggleSection} />
            ) : null}
            <div id="content">{children}</div>
            <Footer />

    </div>
  )
}

export default Layout