import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import ToggleSection from "./toggle-section"

export default function PrimaryMenu({ width, toggleSection }) {

  const pathsWithoutMobileNavTitles = [
    "/",
    "/students",
    "/alumni",
    "/educators",
    "/parents",
    "/frequently-asked-questions/"
  ]

  const windowPathname =
    typeof window !== "undefined" ? window.location.pathname : null

  return (
    <StaticQuery
      query={graphql`
        query MenuQuery {
          menu: wpMenu(name: { eq: "primary" }) {
            id
            menuItems {
              nodes {
                id
                label
                path
              }
            }
          }
        }
      `}
      render={data => (
        <nav
          id="nav-main"
          style={
            pathsWithoutMobileNavTitles.includes(windowPathname) && width < 850
              ? { display: "none" }
              : null
          }
          className={
            !pathsWithoutMobileNavTitles.includes(windowPathname)
              ? "section-active"
              : null
          }
          aria-label="Primary Menu - Alliance for Young Artists & Writers"
        >
          <ul>
            {data.menu.menuItems.nodes.map((menuItem, i) => {
              
              const isBrowser = typeof window !== `undefined`

              if (isBrowser) {

                if (
                  menuItem.path === "/regions/" &&
                  !!localStorage.getItem("selectedShortCode")
                ) {
                  return (
                    <li key={i} role="none">
                      <Link
                        key={menuItem.id}
                        to={
                          menuItem.path+
                          localStorage.getItem("selectedShortCode")
                        }
                        className={
                          menuItem.path === windowPathname ||
                          windowPathname.includes(menuItem.path)
                            ? "selected"
                            : null
                        }
                        tabIndex="0"
                        role="menuitem"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <ToggleSection
                          width={width}
                          toggleSection={toggleSection}
                        >
                          {menuItem.label}
                        </ToggleSection>
                      </Link>
                    </li>
                  )
                } else if (
                  menuItem.path === "/regions/" 
                ) {

                  return (
                    <li key={i} role="none">
                      <Link
                        key={menuItem.id}
                        to={
                          menuItem.path
                        }
                        className={
                          menuItem.path === windowPathname ||
                          windowPathname.includes(menuItem.path)
                            ? "selected"
                            : null
                        }
                        tabIndex="0"
                        role="menuitem"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <ToggleSection
                          width={width}
                          toggleSection={toggleSection}
                        >
                          {menuItem.label}
                        </ToggleSection>
                      </Link>
                    </li>
                  )
                } else {
                  return (
                    <li key={i} role="none">
                      <Link
                        key={menuItem.id}
                        to={menuItem.path}
                        className={
                          menuItem.path === windowPathname ||
                          windowPathname.includes(menuItem.path)
                            ? "selected"
                            : null
                        }
                        tabIndex="0"
                        role="menuitem"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <ToggleSection
                          width={width}
                          toggleSection={toggleSection}
                        >
                          {menuItem.label}
                        </ToggleSection>
                      </Link>
                    </li>
                  )
                }

              }
            })}
          </ul>
        </nav>
      )}
    />
  )
}
