import React, { useState, useEffect } from "react"
import { AiOutlineCloseCircle } from 'react-icons/ai';

const bannerClosedDate = new Date(Date.now())

function Alert() {

  const [openBanner, setOpenBanner] = useState(true)

 useEffect(() => { 
  localStorage.getItem("bannerClosedDate") ? setOpenBanner(false) : setOpenBanner(true)
  });
 

  const toggleBanner = toggle => {
    setOpenBanner(!openBanner)
    localStorage.setItem("bannerClosedDate", bannerClosedDate)
  }

  return (
    <div style={openBanner ? null : {display: "none"}}
  >
    <section id="alert" className="alert">
    <a href="https://newsroom.artandwriting.org/2023/12/18/a-note-from-the-alliance-for-young-artists-writers/" data-open-external-window="true" target="_blank"><u>Visit our blog for an update </u></a>
    &nbsp;on the 2024 Scholastic Art & Writing Awards
    </section>
    <div id="close-alert" className="alert-close" onClick={toggleBanner}><AiOutlineCloseCircle size="1.2em" fill="white" title="Close Alert Banner"/></div>
  </div>
  )
}

export default Alert