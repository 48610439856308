import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const MenuMobile = ({ width, toggleSection }) => {
  const windowPathname =
    typeof window !== "undefined" ? window.location.pathname : null

  const [openFollowMobileMenu, setopenFollowMobileMenu] = useState(false)
  const toggleFollowMenu = () => {
    setopenFollowMobileMenu(!openFollowMobileMenu)
  }

  const data = useStaticQuery(graphql`
    query MobileMenuQuery {
      menu: wpMenu(name: { eq: "primary" }) {
        id
        menuItems {
          nodes {
            id
            label
            path
          }
        }
      }
    }
  `)

  return (
    <div id="mobile-menu">
      <div id="mobile-extras">
        {/* <a href="#" id="mobile-search">
          Search
        </a> */}
        <OutboundLink href="https://portal.artandwriting.org/" id="mobile-login">
          Login
        </OutboundLink>
        {/* <a onClick={toggleFollowMenu} id="mobile-follow">
          Follow
        </a> */}
      </div>
      {openFollowMobileMenu ? (
        <div id="mobile-follow-menu">
          <ul className="social-media">
            <li>
              <a
                href="https://www.instagram.com/artandwriting/"
                className="social-instagram"
                rel="external"
                title="Follow us on Instagram"
                data-open-external-window="true"
                target="_blank"
              >
                <span>Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/artandwriting"
                className="social-twitter"
                rel="external"
                title="Follow us on Twitter"
                data-open-external-window="true"
                target="_blank"
              >
                <span>Twitter</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/ScholasticArtandWritingAwards "
                className="social-facebook"
                rel="external"
                title="Follow us on Facebook"
                data-open-external-window="true"
                target="_blank"
              >
                <span>Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/user/ScholasticGoldKey"
                className="social-youtube"
                rel="external"
                title="Follow us on YouTube"
                data-open-external-window="true"
                target="_blank"
              >
                <span>YouTube</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/groups/4001380/"
                className="social-linkedin"
                rel="external"
                title="Follow us on LinkedIn"
                data-open-external-window="true"
                target="_blank"
              >
                <span>LinkedIn</span>
              </a>
            </li>
          </ul>
        </div>
      ) : null}
      <div id="mobile-main">
        <ul>
          {data.menu.menuItems.nodes.map((menuItem, i) => {
            const isBrowser = typeof window !== `undefined`

            if (isBrowser) {
              if (
                menuItem.path.includes(windowPathname) &&
                menuItem.label === "My Region" &&
                windowPathname !== "/"
              ) {
                return (
                  <li key={i} role="none">
                    <Link
                      key={menuItem.id}
                      to={menuItem.path}
                      className="selected"
                      tabIndex="0"
                      role="menuitem"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {menuItem.label}
                    </Link>
                  </li>
                )
              } else if (
              
                menuItem.label === "My Region" &&
                localStorage.getItem("selectedShortCode")
              ) {
                return (
                  <li key={i} role="none">
                    <Link
                      key={menuItem.id}
                      to={
                        menuItem.path +
                        localStorage.getItem("selectedShortCode")
                      }
                      className="selected"
                      tabIndex="0"
                      role="menuitem"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {menuItem.label}
                    </Link>
                  </li>
                )
              } else {
                return (
                  <li key={i} role="none">
                    <Link
                      key={menuItem.id}
                      to={menuItem.path}
                      tabIndex="0"
                      role="menuitem"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {menuItem.label}
                    </Link>
                  </li>
                )
              }
            }
          })}
        </ul>
      </div>
      <div id="mobile-top">
        <ul>
          <li>
            <Link to="/students">Students</Link>
          </li>
          <li>
            <Link to="/alumni">Alumni</Link>
          </li>
          <li>
            <Link to="/educators">Educators</Link>
          </li>
          <li>
            <Link to="/parents">Parents</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default MenuMobile
